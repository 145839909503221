import React, { useState, useEffect, useCallback, useRef } from "react";
import Header from "../component/Header";
import SubHeader from "../component/SubHeader";
import Footer from "../../../component/Footer";

import axios from "axios";
import {
  Container,
  Form,
  Row,
  Col,
  Nav,
  Button,
  Card,
  Modal,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import config from "../../../component/config";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { VirtualScroller } from "primereact/virtualscroller";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import "./Autocompletee.css";

const Basket = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [baskets, setBaskets] = useState([]);
  const [errors, setErrors] = useState({});
  const [loadingss, setLoadingss] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem("userId");
 

  console.log(userId);
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [items, setItems] = useState([]);
  const [globalFilter, setGlobalFilter] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [backClicked, setBackClicked] = useState(false);

  const [selectedBasketId, setSelectedBasketId] = useState(null);
  const [search, setSearch] = useState("");
  const [equityData, setEquityData] = useState([]);
  const [commodityData, setCommodityData] = useState([]);
  const [fnoData, setFnoData] = useState([]);
  const [selectedStock, setSelectedStock] = useState(null);
  const [selectedExchange, setSelectedExchange] = useState([]);
  const [showNav, setShowNav] = useState(true);
  const authToken = localStorage.getItem("authToken");
  const [showDropdown, setShowDropdown] = useState(false);
  const toast = useRef(null);
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [modalSearch, setModalSearch] = useState("");
  const [showDropdownModal, setShowDropdownModal] = useState(false);
  const [modalItems, setModalItems] = useState([]);
  const [modalEquityData, setModalEquityData] = useState([]);
  const [modalCommodityData, setModalCommodityData] = useState([]);
  const [modalFnoData, setModalFnoData] = useState([]);
  const [equityCount, setEquityCount] = useState(0);
  const [commodityCount, setCommodityCount] = useState(0);
  const [fnoCount, setFnoCount] = useState(0);
  const [margin, setMargin] = useState(null);
  const [showBasketForm, setShowBasketForm] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const titleCase = (str) => {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const handleDeleteRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const handleDeleteRowModal = (index) => {
    const newInstruments = [...currentBasket.instruments];
    newInstruments.splice(index, 1);
    setCurrentBasket((prevBasket) => ({
      ...prevBasket,
      instruments: newInstruments,
    }));
  };

  const [rows, setRows] = useState([]);
  const [rowsModal, setRowsModal] = useState([]);
  const [currentBasket, setCurrentBasket] = useState({
    name: "",
    instruments: [],
  });

  const [editedBasket, setEditedBasket] = useState({
    name: "",
    instruments: [],
  });

  useEffect(() => {
    const defaultName = getFormattedDate();
    setEditedBasket({ name: defaultName });
  }, []);
  const fetchBaskets = async () => {
    setLoading(true);
    try {
      const userId = localStorage.getItem("userId");
      if (!userId) {
        navigate("/commonlogin");
        return;
      }
      const authToken = localStorage.getItem("authToken"); // Fetch the auth token from localStorage
      if (!authToken) {
        console.error("Auth token not found in localStorage");
        return;
      }

      const response = await axios.post(
        `${config.apiDomain}/api/teacher/basket_list_view`,
        {
          teacher_id: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.st === 1) {
        setBaskets(response.data.data);
        console.log("Fetched baskets:", response.data.data); // Debugging log
      } else {
        console.error(response.data.msg || "Failed to fetch baskets");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/commonlogin");
      } else {
        console.error("Network error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBaskets();
  }, []);

  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleDeleteAllBaskets = async () => {
    setShowModal(true);
  };

  const confirmDeleteAll = async () => {
    try {
      setDeleteLoading(true);

      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        console.error("Auth token not found in localStorage");
        navigate("/commonlogin");
        return;
      }

      const response = await axios.post(
        `${config.apiDomain}/api/teacher/delete_baskets_all`,
        {
          teacher_id: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data && response.data.st === 1) {
        console.log("All baskets deleted successfully");
        fetchBaskets();
      } else {
        console.error("Failed to delete all baskets:", response.data.msg);
        console.log("Response data:", response.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error(
          "Unauthorized access, redirecting to common login screen"
        );
        navigate("/commonlogin");
      } else {
        console.error("Failed to delete all baskets:", error.message);
        console.log("Error details:", error);
      }
    } finally {
      setDeleteLoading(false);
      setShowModal(false);
    }
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const handleSearch = async (e) => {
    let searchValue = e.target.value.toUpperCase();

    if (searchValue.length > 15) {
      searchValue = searchValue.slice(0, 15);
    }

    setSearch(searchValue);

    if (searchValue.trim().length >= 3) {
      try {
        const response = await axios.post(
          `${config.apiDomain}/api/teacher/get_instrument_list_new`,
          {
            search: searchValue,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.st === 1) {
          const equity = response.data.data.equity || [];
          const commodity = response.data.data.commodity || [];
          const fno = response.data.data.fno || [];

          setEquityData(equity);
          setCommodityData(commodity);
          setFnoData(fno);
          setShowDropdown(true);

          const instrumentList = [...equity, ...commodity, ...fno].map(
            (item) => item.symbol
          );
          setItems(instrumentList);

          // Calculate the counts
          setEquityCount(equity.length);
          setCommodityCount(commodity.length);
          setFnoCount(fno.length);
        } else {
          setEquityData([]);
          setFnoData([]);
          setCommodityData([]);
          setShowDropdown(false);
          setItems([]);
          setEquityCount(0);
          setCommodityCount(0);
          setFnoCount(0);
          resetSearchData();
        }
      } catch (error) {
        console.error("Error fetching instrument list", error);
      }
    } else {
      setEquityData([]);
      setFnoData([]);
      setCommodityData([]);
      setShowDropdown(false);
      setItems([]);
      setEquityCount(0);
      setCommodityCount(0);
      setFnoCount(0);
      resetSearchData();
    }
  };
  const resetSearchData = () => {
    setEquityData([]);
    setCommodityData([]);
    setFnoData([]);
    setShowDropdown(false);
    setItems([]);
    setEquityCount(0);
    setCommodityCount(0);
    setFnoCount(0);
  };
  const handleStockSelect = (stock) => {
    if (rows.length >= 20) {
      toast.current.show({
        severity: "error",
        summary: " Error",
        detail: "Basket is full.",
        life: 3000,
      });
      setShowDropdown(false);
      return;
    }
    setSelectedStock(stock);
    setSearch("");
    setShowNav(false);
    setShowDropdown(false);

    setRows((prevRows) => [
      ...prevRows,
      {
        instrument: stock.symbol,
        lot_quantity_buffer: "1",
        transactionType: "BUY",
        orderType: "MARKET",
        productType: "CARRYFORWARD",
        exch_seg: stock.exch_seg,
      },
    ]);

    // Ensure the table is visible
    setIsTableVisible(true);
  };

  const handleExchangeSelect = (exchange) => {
    setSelectedExchange(exchange);
  };

  const filteredStocks = (() => {
    switch (selectedExchange) {
      case "Equity":
        return equityData;
      case "FnO":
        return fnoData;
      case "Commodity":
        return commodityData;
      default:
        return [...equityData, ...fnoData, ...commodityData];
    }
  })();

  const handleRefresh = async () => {
    setLoading(true);
    setIsRefreshing(true);
    try {
      const authToken = localStorage.getItem("authToken"); // Fetch the auth token from localStorage
      if (!authToken) {
        navigate("/commonlogin");
        return;
      }

      const response = await axios.post(
        `${config.apiDomain}/api/teacher/basket_list_view`,
        {
          teacher_id: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.st === 1) {
        setBaskets(response.data.data);
      } else if (response.data.st === 2) {
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: response.data.msg,
        });
      } else if (response.data.st === 3 || response.data.st === 4) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.data.msg,
        });
      } else {
        console.error(response.data.msg || "Failed to fetch baskets");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          console.error(
            "Unauthorized access. Redirecting to common login screen."
          );

          navigate("/commonlogin");
        } else if (error.response.data.st) {
          const { st, msg } = error.response.data;
          if (st === 4) {
            toast.current.show({
              severity: "error",
              summary: "Method Not Allowed",
              detail: msg,
            });
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: msg,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "An error occurred",
          });
        }
        console.error("Error response:", error.response);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Network Error",
          detail: "Failed to fetch baskets",
        });
        console.error("Network error", error);
      }
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };

  const handleInputChanges = (index, event) => {
    const { name, value } = event.target;
    const newRows = [...rows];
    newRows[index] = {
      ...newRows[index],
      [name]: value,
    };
    setRows(newRows);
  };

  const filterBaskets = () => {
    if (!globalFilter) {
      return baskets;
    }
    return baskets.filter((basket) =>
      basket.name.toLowerCase().includes(globalFilter.toLowerCase())
    );
  };
  // const handleEditBasket = async (index) => {
  //   try {
  //     const basketId = baskets[index].basket_id; // Assuming you have baskets array defined
  //     const response = await axios.post(
  //       `${config.apiDomain}/api/teacher/get_basket_details`,
  //       {
  //         basket_id: basketId,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${authToken}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     setCurrentBasket({
  //       name: response.data.basket_name,
  //       instruments: response.data.basket_data,
  //     });

  //     const modal = new Modal(document.getElementById("exLargeModal"), {
  //       backdrop: "static",
  //       keyboard: false,
  //     });
  //     modal.show();
  //   } catch (error) {
  //     console.error("Error fetching basket details: ", error);
  //   }
  // };
 

  const handleEditBasket = async (index) => {
    try {
        const basketId = baskets[index].basket_id; // Get basket_id from selected basket

        // First API call to get basket details
        const response = await axios.post(
            `${config.apiDomain}/api/teacher/get_basket_details`,
            {
                basket_id: basketId,
            },
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            }
        );

        if (response.data.st === 1) {
            // Add priority_no dynamically
            const instrumentsWithPriority = response.data.basket_data.map((item, index) => ({
                ...item,
                priority_no: item.priority_no ?? (index + 1), // Ensure priority_no exists, else fallback to index
            }));

            // Set basket details in the state
            setCurrentBasket({
                name: response.data.basket_name,
                instruments: instrumentsWithPriority,
            });

            // Second API call to fetch margin details using the same basket_id
            const marginResponse = await axios.post(
                "https://apasset.xyz/api/teacher/marginCalculate",
                {
                    teacher_id: userId, // Replace with dynamic teacher ID if necessary
                    basket_id: basketId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            // Set margin in the state to display it on the button
            setMargin(marginResponse.data.totalMarginRequired);

            // Show modal
            const modal = new Modal(document.getElementById("exLargeModal"), {
                backdrop: "static",
                keyboard: false,
            });
            modal.show();
        }
    } catch (error) {
        console.error("Error fetching basket or margin details: ", error);
    }
};







 









  const handleInputChange = (index, event, isModal = false) => {
    const { name, value } = event.target;
    let error = "";

    const updatedErrors = { ...errors };
    updatedErrors[index] = {
      ...updatedErrors[index],
      [name]: error,
    };
    setErrors(updatedErrors);

    const updatedInstruments = isModal
      ? [...editedBasket.instruments]
      : [...currentBasket.instruments];
    updatedInstruments[index] = {
      ...updatedInstruments[index],
      [name]: value,
    };

    if (isModal) {
      setEditedBasket({
        ...editedBasket,
        instruments: updatedInstruments,
      });
    } else {
      setCurrentBasket({
        ...currentBasket,
        instruments: updatedInstruments,
      });
    }
  };

  
const onDragEnd = (result) => {
  if (!result.destination) return;

  const reorderedInstruments = Array.from(currentBasket.instruments);
  const [movedItem] = reorderedInstruments.splice(result.source.index, 1);
  reorderedInstruments.splice(result.destination.index, 0, movedItem);

  // Update state with the new order
  setCurrentBasket((prevBasket) => ({
    ...prevBasket,
    instruments: reorderedInstruments,
  }));
};








  
  


const handleExecuteAll = async () => {
  const userId = localStorage.getItem("userId");

  if (!userId) {
    console.error("User ID not found in localStorage");
    return;
  }

  console.log("Instruments before executing orders:", currentBasket.instruments);

  const orderData = currentBasket.instruments.map((instrument, index) => ({
    priority_no: index + 1, // Use sequential priority_no
    instrument: instrument.instrument,
    lot_quantity_buffer: instrument.lot_quantity_buffer,
    transactionType: instrument.transaction_type,
    orderType: instrument.order_type,
    productType: instrument.product_type,
  }));

  console.log("Final order data sent to API:", orderData);

  setLoadingss(true); // Start loading

  try {
    const response = await axios.post(`${config.apiDomain}/api/teacher/execute_orders`, {
      teacher_id: userId,
      order_data: orderData,
    });

    if (response.data.st === 1) {
      console.log("Orders executed successfully.");
      
      // Wait for 1.5 seconds (1500ms) before navigating
      
        navigate("/trade_position");
        window.location.reload();
      
    }else {
      console.error("Execution failed:", response.data.msg);
    }
  } catch (error) {
    console.error("Error executing orders:", error);
  } finally {
    setLoadingss(false); // Stop loading state
  }
};






  
  
  
  
  
  
  
  

  const getFormattedBasketName = (index) => {
    return `Basket ${index + 1}`;
  };
  const getFormattedDate = () => {
    const today = new Date();
    const options = { weekday: "long", day: "numeric", month: "short" };
    return today.toLocaleDateString("en-GB", options);
  };

  useEffect(() => {
    fetchBaskets();
  }, [userId]);

  const handleNameChange = (e) => {
    const { value } = e.target;
    setEditedBasket({ ...editedBasket, name: value });

    if (value.trim() !== "") {
      setValidationErrors({ ...validationErrors, name: "" });
    }
  };

  const handleCreateBasket = async () => {
    const isValid = validateFields();

    if (!isValid) {
      console.log("Validation errors found. Cannot create basket.");
      return;
    }

    setLoadings(true); // Show loading icon
    setShowBasketForm(true);
    try {
      const response = await axios.post(
        `${config.apiDomain}/api/teacher/create_update_basket`,
        {
          basket_id: null,
          basket_name: editedBasket.name,
          teacher_id: userId,
          basket_data: rows,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.status === 200) {
        const data = response.data;

        setBaskets([...baskets, data]);
        setIsTableVisible(false);
        setRows([]);
        setEditedBasket({
          name: "",
          instruments: [],
        });
        setShowModal(false);
        fetchBaskets();
        const successMessage = response.data.message;
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: successMessage || "Basket created successfully",
          life: 3000,
        });
      } else {
        const errorMessage = response.data.message || "Failed to create basket";
        console.error("Failed to create basket:", response.statusText);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMessage,
          life: 3000,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while creating basket";
      console.error("An error occurred while creating basket:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessage,
        life: 3000,
      });
    } finally {
      setLoadings(false); // Hide loading icon
    }
  };

  const tableBodyRef = useRef(null);



  const [validationErrors, setValidationErrors] = useState({
    name: "",
    rows: Array(rows.length).fill({
      instrument: "",
    }),
  });

  const validateFields = () => {
    let isValid = true;
    const errors = { ...validationErrors };

    if (!editedBasket.name.trim()) {
      errors.name = "Basket Name is required";
      isValid = false;
    } else if (editedBasket.name.length < 3) {
      errors.name = "Basket Name must be at least 3 characters";
      isValid = false;
    } else if (!/^[a-zA-Z0-9\s\-_,.]+$/.test(editedBasket.name)) {
      errors.name = "Basket Name contains invalid characters";
      isValid = false;
    } else if (editedBasket.name.length > 50) {
      errors.name = "Basket Name must be at most 50 characters";
      isValid = false;
    } else {
      errors.name = "";
    }

    // Set updated errors state
    setValidationErrors(errors);

    return isValid;
  };

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
    setShowBasketForm(false);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  const isMarketOpen = () => {
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();
    const currentDay = currentTime.getDay();

    // Market open from 9:15 AM to 3:15 PM
    const marketOpenHour = 9;
    const marketOpenMinute = 15;
    const marketCloseHour = 15;
    const marketCloseMinute = 30;

    // Check if today is Saturday (6) or Sunday (0)
    if (currentDay === 0 || currentDay === 6) {
      return false;
    }

    // Check if the current time is within market hours
    if (
      (currentHour > marketOpenHour ||
        (currentHour === marketOpenHour &&
          currentMinute >= marketOpenMinute)) &&
      (currentHour < marketCloseHour ||
        (currentHour === marketCloseHour && currentMinute <= marketCloseMinute))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const [showPopup, setShowPopup] = useState(false); // State for displaying the Popup component

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      // Check if it's 9:15 AM or 3:15 PM
      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 30)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000); // Every minute

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success"; // Green color for 9:15 AM
    } else if (hours === 15) {
      return "danger"; // Red color for 3:15 PM
    }
    return "secondary"; // Default color
  };

  const handleClose = () => setShowModalDelete(false);
  const handleShow = (basketId) => {
    setSelectedBasketId(basketId);
    setShowModalDelete(true);
  };

  const handleDeleteBasket1 = async (basketId) => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        console.error("Auth token not found in localStorage");
        navigate("/commonlogin");
        return;
      }

      const response = await fetch(
        `${config.apiDomain}/api/teacher/delete_basket`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({ basket_id: basketId }),
        }
      );

      if (response.ok) {
        console.log(`Basket ${basketId} deleted successfully`);
        setBaskets(baskets.filter((basket) => basket.basket_id !== basketId));
      } else {
        console.error("Failed to delete basket:", response.statusText);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error(
          "Unauthorized access, redirecting to common login screen"
        );
        navigate("/commonlogin");
      } else {
        console.error("An error occurred while deleting basket:", error);
      }
    }
  };

  const handleModalSearch = async (e) => {
    let searchValue = e.target.value.toUpperCase();

    if (searchValue.length > 15) {
      searchValue = searchValue.slice(0, 15);
    }

    setModalSearch(searchValue);

    if (searchValue.trim().length >= 3) {
      try {
        const response = await axios.post(
          `${config.apiDomain}/api/teacher/get_instrument_list_new`,
          { search: searchValue },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.st === 1) {
          const equity = response.data.data.equity || [];
          const commodity = response.data.data.commodity || [];
          const fno = response.data.data.fno || [];

          setModalEquityData(equity);
          setModalCommodityData(commodity);
          setModalFnoData(fno);
          setShowDropdownModal(true);

          const instrumentList = [...equity, ...commodity, ...fno].map(
            (item) => item.symbol
          );
          setModalItems(instrumentList);

          // Set counts for each category
          setEquityCount(equity.length);
          setCommodityCount(commodity.length);
          setFnoCount(fno.length);
        } else {
          setModalEquityData([]);
          setModalFnoData([]);
          setModalCommodityData([]);
          setShowDropdownModal(false);
          setModalItems([]);
          setEquityCount(0);
          setCommodityCount(0);
          setFnoCount(0);
          // resetSearchData();
        }
      } catch (error) {
        console.error("Error fetching instrument list", error);
      }
    } else {
      setModalEquityData([]);
      setModalFnoData([]);
      setModalCommodityData([]);
      setShowDropdownModal(false);
      setModalItems([]);
      setEquityCount(0);
      setCommodityCount(0);
      setFnoCount(0);
      // resetSearchData();
    }
  };

  const handleModalStockSelect = (stock) => {
    if (currentBasket.length >= 20) {
      toast.current.show({
        severity: "error",
        summary: " Error",
        detail: "Basket full.",
        life: 3000,
      });
      setShowDropdown(false);
      return;
    }
    
    setCurrentBasket((prevBasket) => ({
      ...prevBasket,
      instruments: [
        ...prevBasket.instruments,
        {
          instrument: stock.symbol,

          exch_seg: stock.exch_seg || "N/A",
          lot_quantity_buffer: "1",
          transaction_type: "BUY",
          order_type: "MARKET",
          product_type: "INTRADAY",
        },
      ],
    }));

    setModalSearch("");
    setShowDropdownModal(false);
  };

  const filteredModalStocks = (() => {
    switch (selectedExchange) {
      case "Equity":
        return modalEquityData;
      case "FnO":
        return modalFnoData;
      case "Commodity":
        return modalCommodityData;
      default:
        return [...modalEquityData, ...modalFnoData, ...modalCommodityData];
    }
  })();

  return (
    <div>
      <Toast ref={toast} />
      <Header />
      <SubHeader />
      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="mt-3 container">
        {isMarketOpen() ? (
          <div
            className="text-center "
            style={{
              border: "2px solid green",
              padding: "10px",
              color: "green",
              backgroundColor: "white",
              borderRadius: "5px",
            }}
          >
            Market is Open
          </div>
        ) : (
          <div
            className="text-center "
            style={{
              border: "2px solid orange",
              padding: "10px",
              color: "orange",
              backgroundColor: "white",
              borderRadius: "5px",
            }}
          >
            Market is Closed
          </div>
        )}
      </div>

      <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-container">
          <div className="container flex-grow-1 container-p-y">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-style1 text-secondary">
                <li className="breadcrumb-item">
                  <Link to="/teacher/dashboard" className="text-secondary">
                    <i className="ri-home-7-line ri-lg"></i>
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active text-secondary"
                  aria-current="page"
                >
                  Basket
                </li>
              </ol>
            </nav>
            <div className="row">
              <div className="col-xl-9 col-lg-9 col-md-7 col-12 mt-3">
                <div className="card">
                  <div className="col-7 text-start mb-5 mt-5 ms-3 d-flex align-items-center justify-content-between">
                    <button
                      onClick={handleBack}
                      className="btn rounded-pill btn-outline-secondary btn-xs "
                    >
                      <i className="ri-arrow-left-circle-fill me-1 ri-md"></i>{" "}
                      Back
                    </button>
                    <strong
                      className="mb-0 text-center"
                      style={{ fontSize: "1.4rem" }}
                    >
                      Create Basket
                    </strong>
                  </div>
                  <div className="column">
                    <div className="col-6 ms-3">
                      <div
                        className={`form-floating form-floating-outline ${
                          validationErrors.name ? "has-error" : ""
                        }`}
                      >
                        <input
                          type="text"
                          className={`form-control form-control-sm me-3 ${
                            validationErrors.name ? "is-invalid" : ""
                          }`}
                          placeholder="Enter basket name"
                          value={editedBasket.name}
                          onChange={handleNameChange}
                        />
                        <label htmlFor="brokerApiKey">
                          {" "}
                          <span className="text-danger">* </span>Basket Name
                        </label>
                        {validationErrors.name && (
                          <span className="text-danger">
                            {validationErrors.name}
                          </span>
                        )}
                      </div>
                    </div>

                    <div
                      className="col-6 ms-3 mt-3"
                      style={{ position: "relative" }}
                    >
                      <input
                        type="text"
                        placeholder="Search instruments..."
                        value={search.toUpperCase()}
                        onChange={handleSearch}
                        className="bg-white form-control ps-8"
                        maxLength={15}
                      />
                      <i className="ri ri-search-line position-absolute top-50 start-0 translate-middle-y ms-3"></i>

                      {showDropdown && search && filteredStocks.length > 0 && (
                        <Card
                          style={{
                            position: "absolute",
                            top: "100%",
                            left: 0,
                            width: "100%",
                            zIndex: 1000,
                          }}
                        >
                          <Nav
                            justify
                            variant="tabs"
                            className="bg-body"
                            style={{ height: "45px", overflowX: "hidden" }}
                          >
                            {equityCount > 0 && (
                              <Nav.Item>
                                <Nav.Link
                                  className={`custom-nav-link mt-1 ${
                                    selectedExchange === "Equity"
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() => handleExchangeSelect("Equity")}
                                >
                                  <i className="ri-cash-line ri-lg me-1"></i>
                                  Equity
                                  <small
                                    className={`ms-1 ${
                                      selectedExchange === "Equity"
                                        ? "active"
                                        : "text-muted "
                                    }`}
                                  >
                                    ({equityCount})
                                  </small>
                                </Nav.Link>
                              </Nav.Item>
                            )}

                            {fnoCount > 0 && (
                              <Nav.Item>
                                <Nav.Link
                                  className={`custom-nav-link mt-1 ${
                                    selectedExchange === "FnO" ? "active" : ""
                                  }`}
                                  onClick={() => handleExchangeSelect("FnO")}
                                >
                                  <i className="ri-bar-chart-2-line ri-lg me-1"></i>
                                  FnO
                                  <small
                                    className={`ms-1 ${
                                      selectedExchange === "FnO"
                                        ? "active"
                                        : "text-muted "
                                    }`}
                                  >
                                    ({fnoCount})
                                  </small>
                                </Nav.Link>
                              </Nav.Item>
                            )}

                            {commodityCount > 0 && (
                              <Nav.Item>
                                <Nav.Link
                                  className={`custom-nav-link mt-1 ${
                                    selectedExchange === "Commodity"
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleExchangeSelect("Commodity")
                                  }
                                >
                                  <i className="ri-vip-diamond-line ri-lg me-1"></i>
                                  Commodity
                                  <small
                                    className={`ms-1 ${
                                      selectedExchange === "Commodity"
                                        ? "active"
                                        : "text-muted "
                                    }`}
                                  >
                                    ({commodityCount})
                                  </small>
                                </Nav.Link>
                              </Nav.Item>
                            )}
                          </Nav>

                          <Card.Body
                            className="bg-white p-0"
                            style={{
                              maxHeight: "250px",
                              overflowY: "auto",
                              overflowX: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <Row>
                              {filteredStocks.map((stock, i) => (
                                <Col
                                  key={i}
                                  xs={12}
                                  className={`d-flex align-items-center mb-1 ${
                                    selectedStock === stock
                                      ? "bg-body text-black"
                                      : "hover-bg-secondary"
                                  } custom-col`}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleStockSelect(stock)}
                                >
                                  <div className="custom-col-content ms-3">
                                    <span className="badge rounded-pill bg-label-secondary me-2 ">
                                      {stock.exch_seg}
                                    </span>
                                    <strong>{stock.symbol}</strong>
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          </Card.Body>
                        </Card>
                      )}
                    </div>
                  </div>
                  {isTableVisible && (
                    <div className="m-3 table-responsive table-bordered">
                      <table className="table table-sm">
                        <thead>
                          <tr>
                          <th className="text-center">Sr</th>
                            <th className="text-center">Instrument</th>
                            <th className="text-center">Lot Qty Buffer</th>
                            <th className="text-center">Trans. Type</th>
                            <th className="text-center">Order Type</th>
                            <th className="text-center">Product Type</th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows.map((row, index) => (
                            <tr key={index}>
                                 <td className="w-auto text-center ">
                                {index + 1}
                              </td>
                              <td className="w-25 ">
                                <div className="border border-secondary p-2 rounded">
                                  <p className="fs-5 mb-1 display-6">
                                    {row.instrument}
                                  </p>
                                  <p className="mb-0">
                                    &bull; {row.exch_seg || "N/A"}
                                  </p>
                                </div>
                              </td>
                              <td className="w-auto ">
                                <select
                                  className="form-control "
                                  name="lot_quantity_buffer"
                                  value={row.lot_quantity_buffer}
                                  onChange={(event) =>
                                    handleInputChanges(index, event)
                                  }
                                >
                                  <option value="0">None</option>
                                  <option value="1">1</option>
                                </select>
                              </td>
                              <td className="w-auto text-center">
                                <label
                                  className={`switch ${
                                    row.transactionType === "BUY"
                                      ? "switch-success"
                                      : "switch-danger"
                                  }`}
                                >
                                  <input
                                    type="checkbox"
                                    className="switch-input"
                                    checked={row.transactionType === "BUY"}
                                    onChange={() =>
                                      handleInputChanges(index, {
                                        target: {
                                          name: "transactionType",
                                          value:
                                            row.transactionType === "BUY"
                                              ? "SELL"
                                              : "BUY",
                                        },
                                      })
                                    }
                                  />

                                  <span
                                    className={`switch-toggle-slider ${
                                      row.transactionType === "BUY"
                                        ? "bg-success"
                                        : "bg-danger"
                                    }`}
                                  ></span>
                                  <span
                                    className={`switch-label ${
                                      row.transactionType === "SELL"
                                        ? "text-danger"
                                        : "text-success"
                                    }`}
                                  >
                                    {row.transactionType === "BUY"
                                      ? "BUY"
                                      : "SELL"}
                                  </span>
                                </label>

                                {validationErrors.rows[index]
                                  ?.transactionType && (
                                  <span className="text-danger">
                                    {
                                      validationErrors.rows[index]
                                        .transactionType
                                    }
                                  </span>
                                )}
                              </td>

                              <td className="w-auto text-center">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    className="switch-input"
                                    checked={row.orderType === "MARKET"}
                                    onChange={() =>
                                      handleInputChanges(index, {
                                        target: {
                                          name: "orderType",
                                          value:
                                            row.orderType === "MARKET"
                                              ? "LIMIT"
                                              : "MARKET",
                                        },
                                      })
                                    }
                                  />
                                  <span
                                    className={`switch-toggle-slider ${
                                      row.orderType === "LIMIT" ? "bg-info" : ""
                                    }`}
                                  ></span>
                                  <span
                                    className={`switch-label ${
                                      row.orderType === "LIMIT" ? "" : ""
                                    }`}
                                  >
                                    {row.orderType === "MARKET"
                                      ? "MARKET"
                                      : "LIMIT"}
                                  </span>
                                </label>

                                {validationErrors.rows[index]?.orderType && (
                                  <span className="text-danger">
                                    {validationErrors.rows[index].orderType}
                                  </span>
                                )}

                                {row.orderType !== "MARKET" && (
                                  <div className="d-flex justify-content-center align-items-center mt-2">
                                    <input
                                      type="number"
                                      className="form-control form-control-sm w-75 text-center"
                                      name="price"
                                      placeholder=" Enter Price"
                                      value={row.price}
                                      onChange={(event) =>
                                        handleInputChanges(index, event)
                                      }
                                      disabled={row.orderType === "MARKET"}
                                    />
                                  </div>
                                )}
                              </td>
                              <td className="w-auto">
                                <select
                                  className="form-control "
                                  name="productType"
                                  value={row.productType}
                                  onChange={(event) =>
                                    handleInputChanges(index, event)
                                  }
                                >
                                  <option value="CARRYFORWARD">
                                    CARRYFORWARD
                                  </option>
                                  <option value="DELIVERY">DELIVERY</option>
                                  <option value="MARGIN">MARGIN</option>
                                  <option value="INTRADAY">INTRADAY</option>
                                  <option value="BO">BO</option>
                                </select>
                                {validationErrors.rows[index]?.productType && (
                                  <span className="text-danger">
                                    {validationErrors.rows[index]?.productType}
                                  </span>
                                )}
                              </td>
                              <td className="w-auto">
                                <button
                                  type="button"
                                  className="btn btn-xs btn-outline-warning custom-target-icon"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteRow(index);
                                  }}
                                  data-pr-tooltip="Delete Instrument"
                                  data-pr-position="top"
                                >
                                  <i className="ri-close-large-line ri-sm"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}

                  <div className="text-end mb-5 me-5 mt-3">
                    <button
                      type="submit"
                      className="btn rounded-pill btn-success btn-sm"
                      disabled={loadings}
                      onClick={handleCreateBasket}
                    >
                      {loadings ? (
                        <div className="spinner-border me-2" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <i className="ri-checkbox-circle-line ri-lg me-1"></i>
                      )}{" "}
                      {loadings ? "Saving Data" : "Save Data"}
                    </button>

                    <Toast
                      ref={toast}
                      position="top-right"
                      className="text-start"
                    />
                  </div>
                </div>
              </div>
              {baskets.length > 0 && (
                <div className="col-xl-3 col-lg-3 col-md-5 col-12 mt-3">
                  <div className="card">
                    <div className="col-5 text-start mb-5 mt-2  ms-auto">
                      <button
                        onClick={handleDeleteAllBaskets}
                        className="btn btn-warning btn-xs rounded-pill custom-target-icon"
                        data-pr-tooltip="Delete All Baskets"
                        data-pr-position="top"
                      >
                        <i className="ri-close-large-line me-1 ri-lg"></i>{" "}
                        Delete all
                      </button>

                      <Modal show={showModal} onHide={closeModal}>
                        <Modal.Header closeButton>
                          <Modal.Title>Confirm Deletion</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          Are you sure you want to delete all baskets?
                        </Modal.Body>
                        <Modal.Footer>
                          <div className="d-flex justify-content-between w-100">
                            <button
                              onClick={closeModal}
                              className="btn btn-outline-secondary rounded-pill btn-sm "
                            >
                              <i className="ri-close-large-line me-2"></i>
                              <span>Cancel</span>
                            </button>
                            <button
                              onClick={confirmDeleteAll}
                              disabled={deleteLoading}
                              className="btn btn-danger rounded-pill btn-sm"
                            >
                              <i className="ri-delete-bin-line me-2"></i>
                              {deleteLoading ? "Deleting" : "Delete All"}
                            </button>
                          </div>
                        </Modal.Footer>
                      </Modal>
                    </div>

                    <div className="d-flex justify-content-start ms-4 mb-5 me-3">
                      <IconField iconPosition="left">
                        <InputIcon className="ri ri-search-line"></InputIcon>
                        <InputText
                          type="search"
                          placeholder="Search"
                          value={globalFilter}
                          onChange={(e) => setGlobalFilter(e.target.value)}
                          className="rounded-pill custom-search-box"
                        />
                      </IconField>
                      {loading ? (
                        <div className="d-flex align-items-center">
                          <i
                            className={`ri-refresh-line ri-lg me-3 p-text-secondary ${
                              isRefreshing ? "rotate" : ""
                            }`}
                            role="status"
                          ></i>
                        </div>
                      ) : (
                        <div className="mt-3">
                          <Tooltip target=".custom-target-icon" />
                          <i
                            className="custom-target-icon ri ri-refresh-line ri-lg me-3 p-text-secondary"
                            role="button"
                            data-pr-tooltip="Reload"
                            onClick={handleRefresh}
                            data-pr-position="top"
                          ></i>
                        </div>
                      )}
                    </div>

                    <VirtualScroller
                      items={filterBaskets()}
                      itemSize={70} // Adjust item size as needed
                      itemTemplate={(basket, options) => (
                        <div
                          key={basket.basket_id}
                          className="card border-0 shadow-sm hover-shadow hover-bg-dark my-2 rounded card-hover-light"
                        >
                          <div className="card-body">
                            <div
                              className="d-flex justify-content-between align-items-center"
                            
                             
                              
                            >
                              <h6
                                className="card-title fw-bold"
                                onClick={() => handleEditBasket(options.index)}
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop"
                                style={{ cursor: "pointer" }}
                              >
                                <small>
                                  {titleCase(
                                    basket.name ||
                                      getFormattedBasketName(options.index)
                                  )}
                                </small>
                              </h6>
                              <button
                                type="button"
                                className="btn btn-xs btn-outline-warning custom-target-icon"
                                data-pr-tooltip="Delete Basket"
                                data-pr-position="top"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleShow(basket.basket_id);
                                }}
                              >
                                <i className="ri-close-large-line ri-xs"></i>
                              </button>
                            </div>

                            <div
                              className="d-flex justify-content-between"
                              onClick={() => handleEditBasket(options.index)}
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              style={{ cursor: "pointer" }}
                            >
                              <small>
                                Total: {basket.total_instruments_count}/30
                              </small>
                              <small>
                                <span
                                  className={
                                    basket.buy_instruments_count > 0
                                      ? "text-success"
                                      : ""
                                  }
                                >
                                  <span className="text-secondary">Buy:</span>{" "}
                                  {basket.buy_instruments_count}/30
                                </span>
                              </small>
                              <small>
                                <span
                                  className={
                                    basket.sell_instruments_count > 0
                                      ? "text-danger"
                                      : ""
                                  }
                                >
                                  <span className="text-secondary">Sell:</span>{" "}
                                  {basket.sell_instruments_count}/30
                                </span>
                              </small>
                            </div>
                          </div>
                        </div>
                      )}
                      delay={250}
                      className="border-1 surface-border border-round"
                      style={{ height: "500px" }} // Adjust height as needed
                    />
                  </div>
                </div>
              )}
              <div
                className="modal fade"
                id="staticBackdrop"
               
                data-bs-keyboard="false"
                 data-bs-backdrop="true"
                tabIndex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                    <div className="modal-header d-flex align-items-center">
                      <h5
                        className="modal-title text-center flex-grow-1"
                        id="exLargeModalLabel"
                      >
                        {titleCase(currentBasket?.name)}
                      </h5>
                      <button
                        type="button"
                        className="btn-close ms-3"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={handleCloseModal}
                      ></button>
                    </div>
                    <div
                      className="col-4 mt-3 ms-3"
                      style={{ position: "relative" }}
                    >
                      <input
                        type="text"
                        placeholder="Search instruments..."
                        value={modalSearch.toUpperCase()}
                        onChange={handleModalSearch}
                        className="bg-white form-control ps-8"
                        maxLength={15}
                      />
                      <i className="ri ri-search-line position-absolute top-50 start-0 translate-middle-y ms-3"></i>

                      {showDropdownModal &&
                        modalSearch &&
                        filteredModalStocks.length > 0 && (
                          <Card
                            style={{
                              position: "absolute",
                              top: "100%",
                              left: 0,
                              width: "100%",
                              zIndex: 1050,
                            }}
                          >
                            <Nav
                              justify
                              variant="tabs"
                              className="bg-body mb-0"
                              style={{ height: "45px", overflowX: "hidden" }}
                            >
                              {equityCount > 0 && (
                                <Nav.Item>
                                  <Nav.Link
                                    className={` custom-nav-link  mt-1 ${
                                      selectedExchange === "Equity"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleExchangeSelect("Equity")
                                    }
                                  >
                                    <i className="ri-cash-line ri-lg me-1"></i>{" "}
                                    Equity
                                    <small
                                      className={`ms-1 ${
                                        selectedExchange === "Equity"
                                          ? "active"
                                          : "text-muted "
                                      }`}
                                    >
                                      ({equityCount})
                                    </small>
                                  </Nav.Link>
                                </Nav.Item>
                              )}
                              {fnoCount > 0 && (
                                <Nav.Item>
                                  <Nav.Link
                                    className={`mt-1 custom-nav-link ${
                                      selectedExchange === "FnO" ? "active" : ""
                                    }`}
                                    onClick={() => handleExchangeSelect("FnO")}
                                  >
                                    <i className="ri-bar-chart-2-line ri-lg me-1"></i>{" "}
                                    FnO
                                    <small
                                      className={`ms-1 ${
                                        selectedExchange === "FnO"
                                          ? "active"
                                          : "text-muted "
                                      }`}
                                    >
                                      ({equityCount})
                                    </small>
                                  </Nav.Link>
                                </Nav.Item>
                              )}
                              {commodityCount > 0 && (
                                <Nav.Item>
                                  <Nav.Link
                                    className={`mt-1 custom-nav-link ${
                                      selectedExchange === "Commodity"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleExchangeSelect("Commodity")
                                    }
                                  >
                                    <i className="ri-vip-diamond-line ri-lg me-1"></i>{" "}
                                    Commodity
                                    <small
                                      className={`ms-1 ${
                                        selectedExchange === "Commodity"
                                          ? "active"
                                          : "text-muted "
                                      }`}
                                    >
                                      ({equityCount})
                                    </small>
                                  </Nav.Link>
                                </Nav.Item>
                              )}
                            </Nav>

                            <Card.Body
                              className="bg-white p-0"
                              style={{
                                maxHeight: "250px",
                                overflowY: "auto",
                                overflowX: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <Row>
                                {filteredModalStocks.map((stock, index) => (
                                  <Col
                                    key={index}
                                    xs={12}
                                    className={`d-flex align-items-center mb-1 ${
                                      selectedStock === stock
                                        ? "bg-body text-black"
                                        : "hover-bg-secondary"
                                    } custom-col`}
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleModalStockSelect(stock)
                                    }
                                  >
                                    <div className="custom-col-content ms-3">
                                      <span className="badge rounded-pill bg-label-secondary me-2 ">
                                        {stock.exch_seg}
                                      </span>
                                      <strong>{stock.symbol}</strong>
                                    </div>
                                  </Col>
                                ))}
                              </Row>
                            </Card.Body>
                          </Card>
                        )}
                    </div>

                    <div className="modal-body table-responsive">
                  
      
 
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                             <th className="text-center">SR </th>
                          <th className="text-center">Priority </th>
                         
                            <th className="text-center">Instrument</th>
                            <th className="text-center">Lot Qty Buffer</th>
                            <th className="text-center">Trans. Type</th>
                            <th className="text-center">Order Type</th>
                            <th className="text-center">Product Type</th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
<DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId="table">
      {(provided) => (
        <tbody {...provided.droppableProps} ref={provided.innerRef}>
         
            {currentBasket?.instruments.slice(0, 20).map((row, index) =>(
            <Draggable key={row.instrument} draggableId={row.instrument} index={index}>
              {(provided) => (
                <tr
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  {/* SR Column */}
                  <td className="text-center">{index + 1}</td> {/* Sequential SR */}

                  {/* Drag Handle */}
                  <td className="text-center">
                    <i className="ri ri-list-check ri-lg"></i>
                  </td>

                  {/* Instrument */}
                  <td>{row.instrument || "N/A"}</td>

                  {/* Lot Quantity Buffer */}
                  <td>
                    <select
                      className="form-control"
                      name="lot_quantity_buffer"
                      value={row?.lot_quantity_buffer || "0"}
                      onChange={(event) => handleInputChange(index, event)}
                    >
                      <option value="0">None</option>
                      <option value="1">1</option>
                    </select>
                  </td>

               <td className="w-auto text-center">
        <label
          className={`switch ${
            row?.transaction_type === "BUY"
              ? "switch-success"
              : "switch-danger"
          }`}
        >
          <input
            type="checkbox"
            className="switch-input"
            checked={row?.transaction_type === "BUY"}
            onChange={() =>
              handleInputChange(index, {
                target: {
                  name: "transaction_type",
                  value: row?.transaction_type === "BUY" ? "SELL" : "BUY",
                },
              })
            }
          />
          <span
            className={`switch-toggle-slider ${
              row?.transaction_type === "BUY"
                ? "bg-success"
                : "bg-danger"
            }`}
          ></span>
          <span
            className={`switch-label ${
              row?.transaction_type === "BUY"
                ? "text-success"
                : "text-danger"
            }`}
          >
            {row?.transaction_type || "N/A"}
          </span>
        </label>
      </td>
      <td className="w-auto text-center">
        <label
          className={`switch ${
            row?.order_type === "LIMIT" ? "switch-info" : ""
          }`}
        >
          <input
            type="checkbox"
            className="switch-input"
            checked={row?.order_type === "MARKET"}
            onChange={() =>
              handleInputChange(index, {
                target: {
                  name: "order_type",
                  value: row?.order_type === "MARKET" ? "LIMIT" : "MARKET",
                },
              })
            }
          />
          <span
            className={`switch-toggle-slider ${
              row?.order_type === "LIMIT" ? "bg-info" : ""
            }`}
          ></span>
          <span className="switch-label">
            {row?.order_type || "N/A"}
          </span>
        </label>
      </td>
 <td className="w-auto text-center">
        <select
          className="form-control"
          name="product_type"
          value={row?.product_type || "CARRYFORWARD"}
          onChange={(event) => handleInputChange(index, event)}
        >
          <option value="CARRYFORWARD">CARRYFORWARD</option>
          <option value="DELIVERY">DELIVERY</option>
          <option value="MARGIN">MARGIN</option>
          <option value="INTRADAY">INTRADAY</option>
          <option value="BO">BO</option>
        </select>
      </td>
      <td className="w-auto text-center">
        <button
          type="button"
          className="btn btn-xs btn-outline-warning"
          onClick={() => handleDeleteRowModal(index)}
        >
          <i className="ri-close-large-line ri-sm"></i>
        </button>
      </td>
                </tr>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </tbody>
      )}
    </Droppable>
  </DragDropContext>


                      </table>
                    
                    
                    </div>

                    <div className="modal-footer">
                    <div className="me-auto">
  Margin: {margin !== null ? `₹${margin}` : "Loading..."}
</div>


                      <div className="d-flex align-items-center">
                        {message && (
                          <div className="d-flex align-items-center">
                            <i className="ri ri-checkbox-circle-fill text-success me-2 ri-lg"></i>
                            <div>
                              <div className="text-muted me-3">
                                Executing these instruments on <br /> teacher
                                and all student accounts...
                              </div>
                            </div>
                          </div>
                        )}

                        <button
                          type="button"
                          className="btn btn-info d-flex align-items-center"
                          onClick={handleExecuteAll}
                          disabled={loadingss}
                        >
                          {loadingss ? (
                            <i className="ri-loader-line ri-lg me-1"></i>
                          ) : (
                            <i className="ri-checkbox-circle-line ri-lg me-1"></i>
                          )}{" "}
                          {loadingss ? "Executing All...." : "Execute All"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteConfirmationModal
        show={showModalDelete}
        handleClose={handleClose}
        handleConfirmDelete={handleDeleteBasket1}
        basketId={selectedBasketId}
      />

      <Footer />
    </div>
  );
};

export default Basket;
const DeleteConfirmationModal = ({
  show,
  handleClose,
  handleConfirmDelete,
  basketId,
}) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this basket?</Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <button
            onClick={handleClose}
            className="btn btn-outline-secondary rounded-pill btn-sm "
          >
            <i className="ri-close-large-line me-2"></i>
            <span>Cancel</span>
          </button>
          <button
            onClick={() => {
              handleConfirmDelete(basketId);
              handleClose();
            }}
            className="btn btn-danger rounded-pill btn-sm"
          >
            <i className="ri-delete-bin-line me-2"></i>
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red"; // Apply custom modal background colors
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};
