import React, { useState, useEffect, useRef } from "react";
import Footer from "../../../component/Footer";
import Header from "../component/Header";
import config from "../../../component/config";
import SubHeader from "../component/SubHeader";
import { Button } from "primereact/button";
import { Modal } from "react-bootstrap";
import { Toast } from "primereact/toast";
import { useNavigate, Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { VirtualScroller } from "primereact/virtualscroller";
import { Tooltip } from "primereact/tooltip";
import axios from "axios";
// const userId = localStorage.getItem("user_id");
const userId = localStorage.getItem("userId");

const Position = () => {
  const [loading, setLoading] = useState(false);
  const [currentStudentId, setCurrentStudentId] = useState(null);
  const [selectedStudentName, setSelectedStudentName] = useState(null);
  const [loadingMap, setLoadingMap] = useState({});
  const [loadingMaps, setLoadingMaps] = useState({});
  const [loadingpending, setLoadingPending] = useState(false);
  const [loadingexit, setLoadingexit] = useState(false);
  const [loadingRefresh, setLoadingRefresh] = useState(false);
  const [loadingRefreshes, setLoadingRefreshes] = useState(false);
  const toast = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenStudent, setIsModalOpenStudent] = useState(false);
  const [dataOrder, setDataOrder] = useState([]);
  const [dataStudentOrder, setDataStudentOrder] = useState([]);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [loadingStudentOrder, setLoadingStudentOrder] = useState(false);
  const [error, setError] = useState(null);


  const [globalFilter, setGlobalFilter] = useState("");
  const [activeTab, setActiveTab] = useState("all");

  const navigate = useNavigate();
  const [backClicked, setBackClicked] = useState(false);

  const [data, setData] = useState([]);
  const [filteredeData, setFilteredeData] = useState(data);
  const [searchTerm, setSearchTerm] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    filterData(searchTerm);
  }, [searchTerm]);

  const filterData = (searchTerm) => {
    const filtered = filteredeData.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredeData(filtered);
  };

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
  };

  const clearSearch = () => {
    setSearchTerm("");
    setFilteredeData(data);
  };

  const handleRefreshes = async () => {
    setLoadingRefreshes(true);
    try {
      const authToken = localStorage.getItem("authToken"); // Fetch the auth token from localStorage
      if (!authToken) {
        console.error("Auth token not found in localStorage");
        navigate("/commonlogin"); // Example using React Router to navigate to common login screen
        return;
      }

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
        },
        body: JSON.stringify({ teacher_id: userId }),
      };

      const response = await fetch(
        `${config.apiDomain}/api/teacher/get_position_student_list`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data && data.st === 1) {
        setData(
          data.data.map((student) => ({
            name: student.name,
            student_id: student.student_id, // Updated from user_id to student_id
            positions: student.orders.map((order) => ({
              symboltoken: order.symboltoken,
              tradingsymbol: order.tradingsymbol,
              ltp: order.ltp,
              pnl: order.pnl,
              avgprice: order.avgprice,
              transactiontype: order.transactiontype,
            })),
          }))
        );

        setFilteredeData(
          data.data.map((student) => ({
            name: student.name,
            user_id: student.user_id,
            instruments: student.orders.map((order) => order.symbol),
            lots: student.orders.map((order) => ({
              size: order.buy_lots,
              price: order.buy_price,
              status: order.sell_lots > 0 ? "completed" : "pending",
              sell: order.sell_lots,
              sellPrice: order.sell_price,
              symboltoken: order.token,
            })),
          }))
        );
      } else if (data.st === 2) {
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: data.msg || "Warning: Data may not be refreshed",
          life: 3000,
        });
      } else if (data.st === 3 || data.st === 4) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: data.msg || "Failed to fetch data",
          life: 3000,
        });
      } else {
        console.error("No data found in get_position_student_list");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error(
          "Unauthorized access, redirecting to common login screen"
        );
        navigate("/commonlogin"); // Navigate to common login screen on 401 error
      } 
      else {
        console.error("Error fetching order placed student list:", error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Network error: Failed to fetch data",
          life: 3000,
        });
      }
    } finally {
      setLoadingRefreshes(false);
    }
  };

  const getOrderPlacedStudentList = async () => {
    try {
      setLoading(true);

      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        console.error("Auth token not found in localStorage");
        navigate("/commonlogin");
        return;
      }

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ teacher_id: userId }),
      };

      const response = await fetch(
        `${config.apiDomain}/api/teacher/get_position_student_list`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.st === 1) {
        const formattedData = data.data.map((student) => ({
          name: student.name,
          student_id: student.student_id, // Updated from user_id to student_id
          positions: student.orders.map((order) => ({
            symboltoken: order.symboltoken,
            tradingsymbol: order.tradingsymbol,
            ltp: order.ltp,
            pnl: order.pnl,
            avgprice: order.avgprice,
            transactiontype: order.transactiontype,

            
          })),
        }));

        setData(formattedData);
        setFilteredeData(formattedData);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching order placed student list:", error);
     
    } finally {
      setLoading(false);
    }
  };


 const handleExitAllInstruments = async (student_id) => {
   setLoadingMaps((prev) => ({ ...prev, [student_id]: true }));
   try {
     const requestOptions = {
       method: "POST",
       headers: { "Content-Type": "application/json" },
       body: JSON.stringify({
         student_id: student_id, // Use student_id
       }),
     };

     const response = await fetch(
       `${config.apiDomain}/api/teacher/exit_students_all_instrument`,
       requestOptions
     );


     if (!response.ok) {
       throw new Error(`HTTP error! Status: ${response.status}`);
     }

     const data = await response.json();

     if (data.st === 1) {
       toast.current.show({
         severity: "success",
         summary: "Success",
         detail: data.msg || "All Exit placed successfully",
         life: 3000,
       });
     } else if (data.st === 2) {
       toast.current.show({
         severity: "warn",
         summary: "Warning",
         detail: data.msg || "Student not found",
         life: 3000,
       });
     } else {
       toast.current.show({
         severity: "error",
         summary: "Error",
         detail: data.msg || "Failed to exit instruments",
         life: 3000,
       });
     }

     await getOrderPlacedStudentList(); // Refresh data
   } catch (error) {
     console.error("Error exiting all student instruments:", error);
   } finally {
     setLoadingMaps((prev) => ({ ...prev, [student_id]: false }));
   }
 };


  const handleExit = async (studentId, instrumentData) => {
    const key = `${studentId}-${instrumentData.tradingsymbol}`;
    console.log(`Setting loader for key: ${key}`);

    setLoadingMap((prev) => ({ ...prev, [key]: true }));

    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          student_id: studentId,
          instrument_data: instrumentData,
        }),
      };

      const response = await fetch(
        `${config.apiDomain}/api/teacher/exit_student_instrument`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("Exit student instrument response:", responseData);

      if (responseData.st === 1) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: responseData.msg || "Exit Order placed on accounts",
          life: 3000,
        });
      } else if (responseData.st === 2) {
        // Handling different 'st: 2' scenarios
        if (responseData.msg === "Student not found") {
          toast.current.show({
            severity: "warn",
            summary: "Warning",
            detail: "Student not found",
            life: 3000,
          });
        } else if (responseData.msg.includes("'student_id'")) {
          toast.current.show({
            severity: "warn",
            summary: "Warning",
            detail: "Missing or invalid 'student_id'",
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: "warn",
            summary: "Warning",
            detail: responseData.msg || "Unexpected response",
            life: 3000,
          });
        }
      } else if (responseData.st === 4) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: responseData.msg || "Invalid request method",
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "info",
          summary: "Info",
          detail: "Unexpected response from server",
          life: 3000,
        });
      }


      await getPositionList();
      await getOrderPlacedStudentList();
    } catch (error) {
      console.error("Error exiting student instrument:", error);
    } finally {
      console.log(`Removing loader for key: ${key}`);
      setLoadingMap((prev) => ({ ...prev, [key]: false }));
    }
  };

 

const filteredData = data.filter((item) => {
  // Check if the name matches
  const nameMatch = item.name
    .toLowerCase()
    .includes(globalFilter.toLowerCase());

  // Check if any tradingsymbol in the student's positions matches
  const instrumentsMatch = item.positions?.some((position) =>
    position.tradingsymbol.toLowerCase().includes(globalFilter.toLowerCase())
  );


  return nameMatch || instrumentsMatch;
});


  const [positionData, setPositionData] = useState({
    openPositions: [],
    closedPositions: [],
    totalRealisedPnl: 0.0,
    totalUnrealisedPnl: 0.0,
  });
  const [selectedPositions, setSelectedPositions] = useState(new Set());
  const [allSelected, setAllSelected] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0); // New state variable
  const [showActionColumn, setShowActionColumn] = useState(false);

  const getPositionList = async () => {
    setLoading(true);
    setIsUpdating(true);
    try {
      const authToken = localStorage.getItem("authToken"); // Fetch the auth token from localStorage
      if (!authToken) {
        console.error("Auth token not found in localStorage");
        // Redirect to common login screen or handle unauthorized access
        navigate("/commonlogin"); // Example using React Router to navigate to common login screen
        return;
      }

      const userId = localStorage.getItem("userId");
      const response = await fetch(
        `${config.apiDomain}/api/teacher/get_position_list`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
          },
          body: JSON.stringify({ teacher_id: userId }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      let openPositionRows = [];
      let closedPositionRows = [];
      let totalRealisedPnl = 0.0;
      let totalUnrealisedPnl = 0.0;

      data.position_list.forEach((item) => {
        const dailyDiffPercent = (
          ((item.ltp - item.close) / item.close) *
          100
        ).toFixed(2);

        const netqty = parseInt(item.netqty);

        const lotsize = parseInt(item.lotsize);
        const lotquantity = parseInt(Math.abs(netqty) / lotsize);

        const ordertype = netqty > 0 ? "BUY" : "SELL";
        const color_ordertype = netqty > 0 ? "text-success" : "text-danger";
        const avg_price =
          netqty > 0 ? item.totalbuyavgprice : item.totalsellavgprice;
        const color_pnl =
          item.pnl > 0
            ? "text-success"
            : item.pnl < 0
            ? "text-danger"
            : "text-muted";
        const color_diff_percent =
          dailyDiffPercent > 0 ? "text-success" : "text-danger";

        if (netqty !== 0) {
          totalUnrealisedPnl += parseFloat(item.unrealised);

          openPositionRows.push({
            tradingsymbol: item.tradingsymbol,
            symboltoken: item.symboltoken,
            producttype: item.producttype,
            optiontype: item.optiontype,
            exchange: item.exchange,
            lotsize: item.lotsize,
            netqty: item.netqty,
            ltp: item.ltp,
            close: item.close,
            totalbuyavgprice: item.totalbuyavgprice,
            totalsellavgprice: item.totalsellavgprice,
            pnl: item.pnl,
            dailyDiffPercent,
            ordertype,
            color_ordertype,
            color_pnl,
            color_diff_percent,

            lotquantity,
            avg_price,
          });
        } else {
          totalRealisedPnl += parseFloat(item.realised);
          closedPositionRows.push(
            <tr key={item.tradingsymbol}>
              <td style={{ wordWrap: "break-word", maxWidth: "150px" }}>
                <span className="instrument_symbol">{item.tradingsymbol}</span>{" "}
                <span className="d-none instrument_token">
                  {item.symboltoken}
                </span>
              </td>
              <td style={{ wordWrap: "break-word", maxWidth: "110px" }}>
                {item.producttype}
              </td>
              <td>{item.optiontype}</td>
              <td>{item.exchange}</td>
              <td>
                0 Lots{" "}
                <span className="text-body-tertiary">
                  (1 Lot = {item.lotsize})
                </span>
              </td>
              <td>
                <span className={color_diff_percent}>{item.ltp} ₹</span>{" "}
                <span className="text-body-tertiary">
                  ({dailyDiffPercent}%)
                </span>
              </td>
              <td>{item.totalbuyavgprice} ₹</td>
              <td>{item.totalsellavgprice} ₹</td>
              <td className={color_pnl}>{item.pnl} ₹</td>
            </tr>
          );
        }
      });

      setPositionData({
        openPositions: openPositionRows,
        closedPositions: closedPositionRows,
        totalRealisedPnl,
        totalUnrealisedPnl,
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error(
          "Unauthorized access, redirecting to common login screen"
        );
        navigate("/commonlogin"); // Navigate to common login screen on 401 error
      } else {
        console.error("Error occurred while fetching position list:", error);
      }
    } finally {
      setLoading(false); // Ensure loading state is set to false
      setIsUpdating(false);
    }
  };

  const handleCheckboxChange = (tradingsymbol, symboltoken, lotquantity) => {
    const key = JSON.stringify({ tradingsymbol, symboltoken, lotquantity });
    setSelectedPositions((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(key)) {
        newSelected.delete(key);
      } else {
        newSelected.add(key);
      }
      setSelectedCount(newSelected.size);
      return newSelected;
    });
  };

  const handleToggleSelectAll = () => {
    if (allSelected) {
      setSelectedPositions(new Set());
      setSelectedCount(0);
      setAllSelected(false);
      setShowActionColumn(false);
    } else {
      const newSelected = new Set();
      positionData.openPositions.forEach((item) => {
        if (item.netqty !== 0) {
          const key = JSON.stringify({
            tradingsymbol: item.tradingsymbol,
            symboltoken: item.symboltoken,
            lotquantity: parseInt(item.netqty / item.lotsize),
          });
          newSelected.add(key);
        }
      });
      setSelectedPositions(newSelected);
      setSelectedCount(newSelected.size);
      setAllSelected(true);
      setShowActionColumn(true);
    }
  };

  const isChecked = (tradingsymbol, symboltoken, lotquantity) => {
    const key = JSON.stringify({ tradingsymbol, symboltoken, lotquantity });
    return selectedPositions.has(key);
  };

 


  const exitPosition = async () => {
    setLoadingexit(true);
    try {
      const userId = localStorage.getItem("userId");
      const order_data = Array.from(selectedPositions).map((positionString) => {
        const { tradingsymbol, symboltoken, lotquantity } =
          JSON.parse(positionString);
        // Use the lotquantity from the selected position
        return {
          instrument: tradingsymbol,
          symboltoken,
          exit_lot: lotquantity,
        };
      });

      const response = await fetch(
        `${config.apiDomain}/api/teacher/exit_position`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ teacher_id: userId, order_data }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Position exited successfully:", data);
      console.log("Selected count:", selectedCount);

      if (data.st === 1) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: data.msg || "Exit Order placed on accounts",
          life: 3000,
        });
      } else if (data.st === 2) {
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: data.msg || "Teacher not found",
          life: 3000,
        });
      } else if (data.st === 3 || data.st === 4) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: data.msg || "Invalid request method",
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "info",
          summary: "Info",
          detail: "Unexpected response from server",
          life: 3000,
        });
      }

      await getPositionList();
      await getOrderPlacedStudentList();
    } catch (error) {
      console.error("Error occurred while exiting position:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while exiting position",
        life: 3000,
      });
    } finally {
      setLoadingexit(false);
    }
  };

  const handleExitAllPending = async () => {
    setLoadingPending(true);
    try {
      const userId = localStorage.getItem("userId");
      const response = await fetch(
        `${config.apiDomain}/api/teacher/exit_all_student_pending`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            teacher_id: userId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Exited all pending positions successfully:", data);

      if (data.st === 1) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: data.msg || "Exit Order placed on accounts",
          life: 3000,
        });
      } else if (data.st === 2) {
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: data.msg || "Teacher not found",
          life: 3000,
        });
      } else if (data.st === 3 || data.st === 4) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: data.msg || "Failed to exit pending positions",
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "info",
          summary: "Info",
          detail: "Unexpected response from server",
          life: 3000,
        });
      }

      await getPositionList();
      await handleRefreshes();
    } catch (error) {
    console.error("Error occurred while exiting all pending positions:", error);
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: "An error occurred while exiting all pending positions",
      life: 3000,
    });
    } finally {
      setLoadingPending(false);
    }
  };

  const handleRefresh = async () => {
    setLoadingRefresh(true);
    try {
      const authToken = localStorage.getItem("authToken"); // Fetch the auth token from localStorage
      if (!authToken) {
        console.error("Auth token not found in localStorage");
        // Redirect to common login screen or handle unauthorized access
        navigate("/commonlogin"); // Example using React Router to navigate to common login screen
        return;
      }

      const userId = localStorage.getItem("userId");
      const response = await fetch(
        `${config.apiDomain}/api/teacher/get_position_list`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
          },
          body: JSON.stringify({ teacher_id: userId }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.st === 1) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Data refreshed successfully",
          life: 3000,
        });
      } else if (data.st === 2) {
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: data.msg || "Warning: Data may not be fully refreshed",
          life: 3000,
        });
      } else if (data.st === 3 || data.st === 4) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: data.msg || "Failed to fetch data",
          life: 3000,
        });
      } else {
        console.error("Unexpected status code:", data.st);
      }

      let openPositionRows = [];
      let closedPositionRows = [];
      let totalRealisedPnl = 0.0;
      let totalUnrealisedPnl = 0.0;

      data.position_list.forEach((item) => {
        const dailyDiffPercent = (
          ((item.ltp - item.close) / item.close) *
          100
        ).toFixed(2);

        const netqty = parseInt(item.netqty);

        const lotsize = parseInt(item.lotsize);
        const lotquantity = parseInt(Math.abs(netqty) / lotsize);
        const ordertype = netqty > 0 ? "BUY" : "SELL";
        const color_ordertype = netqty > 0 ? "text-success" : "text-danger";
        const avg_price =
          netqty > 0 ? item.totalbuyavgprice : item.totalsellavgprice;
        const color_pnl = item.pnl > 0 ? "text-success" : "text-danger";
        const color_diff_percent =
          dailyDiffPercent > 0 ? "text-success" : "text-danger";

        if (netqty !== 0) {
          totalUnrealisedPnl += parseFloat(item.unrealised);

          openPositionRows.push({
            tradingsymbol: item.tradingsymbol,
            symboltoken: item.symboltoken,
            producttype: item.producttype,
            optiontype: item.optiontype,
            exchange: item.exchange,
            lotsize: item.lotsize,
            netqty: item.netqty,
            ltp: item.ltp,
            close: item.close,
            totalbuyavgprice: item.totalbuyavgprice,
            totalsellavgprice: item.totalsellavgprice,
            pnl: item.pnl,
            dailyDiffPercent,
            ordertype,
            color_ordertype,
            color_pnl,
            color_diff_percent,

            lotquantity,
            avg_price,
          });
        } else {
          totalRealisedPnl += parseFloat(item.realised);
          closedPositionRows.push(
            <tr key={item.tradingsymbol}>
              <td style={{ wordWrap: "break-word", maxWidth: "150px" }}>
                <span className="instrument_symbol">{item.tradingsymbol}</span>{" "}
                <span className="d-none instrument_token">
                  {item.symboltoken}
                </span>
              </td>
              <td style={{ wordWrap: "break-word", maxWidth: "110px" }}>
                {item.producttype}
              </td>
              <td>{item.optiontype}</td>
              <td>{item.exchange}</td>
              <td>
                0 Lots{" "}
                <span className="text-body-tertiary">
                  (1 Lot = {item.lotsize})
                </span>
              </td>
              <td>
                <span className={color_diff_percent}>{item.ltp} ₹</span>{" "}
                <span className="text-body-tertiary">
                  ({dailyDiffPercent}%)
                </span>
              </td>
              <td>{item.totalbuyavgprice} ₹</td>
              <td>{item.totalsellavgprice} ₹</td>
              <td className={color_pnl}>{item.pnl} ₹</td>
            </tr>
          );
        }
      });

      setPositionData({
        openPositions: openPositionRows,
        closedPositions: closedPositionRows,
        totalRealisedPnl,
        totalUnrealisedPnl,
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error(
          "Unauthorized access, redirecting to common login screen"
        );
        navigate("/commonlogin"); // Navigate to common login screen on 401 error
      } else {
        console.error("Failed to fetch data:", error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "An error occurred while fetching data",
          life: 3000,
        });
      }
    } finally {
      setLoadingRefresh(false);
    }
  };

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };
  const handleLotSizeChange = (event, tradingsymbol, symboltoken) => {
    const selectedLotSize = parseInt(event.target.value, 10);

    setSelectedPositions((prevSelected) => {
      const updatedSelected = new Set(prevSelected);

      // Create the key for the item that needs its lot size updated
      const key = JSON.stringify({ tradingsymbol, symboltoken });

      // Check if the key exists in the selected positions
      if (updatedSelected.has(key)) {
        // Remove the existing item with the old lot size
        updatedSelected.delete(key);

        // Add the updated item with the new lot size
        updatedSelected.add(
          JSON.stringify({
            tradingsymbol,
            symboltoken,
            lotquantity: selectedLotSize,
          })
        );
      }

      return updatedSelected;
    });
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  const isMarketOpen = () => {
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();
    const currentDay = currentTime.getDay();

    // Market open from 9:15 AM to 3:15 PM
    const marketOpenHour = 9;
    const marketOpenMinute = 15;
    const marketCloseHour = 15;
    const marketCloseMinute = 30;

    // Check if today is Saturday (6) or Sunday (0)
    if (currentDay === 0 || currentDay === 6) {
      return false;
    }

    // Check if the current time is within market hours
    if (
      (currentHour > marketOpenHour ||
        (currentHour === marketOpenHour &&
          currentMinute >= marketOpenMinute)) &&
      (currentHour < marketCloseHour ||
        (currentHour === marketCloseHour && currentMinute <= marketCloseMinute))
    ) {
      return true;
    } else {
      return false;
    }
  };
  const [showPopup, setShowPopup] = useState(false); // State for displaying the Popup component

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      // Check if it's 9:15 AM or 3:15 PM
      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 30)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000); // Every minute

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  // Helper function to determine modal button variant
  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success"; // Green color for 9:15 AM
    } else if (hours === 15) {
      return "danger"; // Red color for 3:15 PM
    }
    return "secondary"; // Default color
  };

  // Auto Refresh
  useEffect(() => {
    // Hit the APIs after 1 second on initial render
    const initialTimeout = setTimeout(() => {
      getOrderPlacedStudentList();
      getPositionList();
    }, 1000);
  
    // Set up interval to hit the APIs every 10 seconds
    const interval = setInterval(() => {
      getOrderPlacedStudentList();
      getPositionList();
    }, 10000);
  
    // Clean up both the timeout and interval when the component unmounts
    return () => {
      clearTimeout(initialTimeout);
      clearInterval(interval);
    };
  }, []);
  

  const handleViewClick = async (userId) => {
    try {
      setLoadingOrder(true); // Start loading
      setError(null); // Clear previous errors
  
      const authToken = localStorage.getItem("authToken");
  
      if (!authToken) {
       
        return;
      }
  
      const response = await axios.post(
        `${config.apiDomain}/api/common/order_book`,
        { user_id: userId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
  
      if (response.data && response.data.data) {
        setDataOrder(response.data.data); // Set response data
        setIsModalOpen(true); // Open modal
      } else {
        setError(new Error("No data found"));
      }
    } catch (error) {
      const errorMsg = error.response?.data?.msg || error.message || "Failed to fetch data";
      setError(new Error(errorMsg));
    } finally {
      setLoadingOrder(false); // Stop loading
    }
  };

  const fetchDataStudentOrderBook = async (studentId) => {
    try {
      setLoadingStudentOrder(true);
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        
        return;
      }

      const response = await axios.post(
        `${config.apiDomain}/api/common/order_book`,
        { user_id: studentId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data && response.data.data) {
        setDataStudentOrder(response.data.data);
        setIsModalOpenStudent(true);
      } else {
        setDataStudentOrder([]);
        
      }
    } catch (error) {
      console.error("Error fetching student order book:", error);
    } finally {
      setLoadingStudentOrder(false);
    }
  };




  return (
    <>
      <Header />
      <SubHeader />
      <Toast ref={toast} />

      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="mt-3 container">
        {isMarketOpen() ? (
          <div
            className="text-center "
            style={{
              border: "2px solid green",
              padding: "10px",
              color: "green",
              backgroundColor: "white",
              borderRadius: "5px",
            }}
          >
            Market is Open
          </div>
        ) : (
          <div
            className="text-center"
            style={{
              border: "2px solid orange",
              padding: "10px",
              color: "orange",
              backgroundColor: "white",
              borderRadius: "5px",
            }}
          >
            Market is Closed
          </div>
        )}
      </div>

      <div className="container flex-grow-1 container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-secondary">
            <li className="breadcrumb-item">
              <Link to="/teacher/dashboard" className="text-secondary">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Position
            </li>
          </ol>
        </nav>
        <div className="row ">
          <div className="col-xl-9 col-lg-9 col-md-7 col-12">
            <div className="card mb-3" style={{ overflow: "hidden" }}>
              <div className="col text-start mb-5 mt-5 ms-5">
                <button
                  onClick={handleBack}
                  className="btn rounded-pill btn-outline-secondary btn-xs"
                >
                  <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
                </button>
              </div>
              <div className=" text-center">
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <h4
                      className={
                        positionData.totalUnrealisedPnl < 0
                          ? "text-danger"
                          : positionData.totalUnrealisedPnl > 0
                          ? "text-success"
                          : "text-muted"
                      }
                    >
                      {positionData.totalUnrealisedPnl === 0
                        ? "0.00"
                        : positionData.totalUnrealisedPnl.toFixed(2)}{" "}
                      ₹
                    </h4>

                    <p className="mb-0">Unrealised Profit & Loss</p>
                  </div>
                  <div className="col-md-4 mb-3">
                    <h4
                      className={
                        positionData.totalRealisedPnl < 0
                          ? "text-danger"
                          : positionData.totalRealisedPnl > 0
                          ? "text-success"
                          : "text-muted"
                      }
                    >
                      {positionData.totalRealisedPnl === 0
                        ? "0.00"
                        : positionData.totalRealisedPnl.toFixed(2)}{" "}
                      ₹
                    </h4>

                    <p className="mb-0">Realised Profit & Loss</p>
                  </div>
                  <div className="col-md-4 mb-3">
                    <h4
                      className={
                        positionData.totalRealisedPnl +
                          positionData.totalUnrealisedPnl ===
                        0
                          ? "text-muted"
                          : positionData.totalRealisedPnl +
                              positionData.totalUnrealisedPnl <
                            0
                          ? "text-danger"
                          : "text-success"
                      }
                    >
                      {(
                        positionData.totalRealisedPnl +
                        positionData.totalUnrealisedPnl
                      ).toFixed(2)}{" "}
                      ₹
                    </h4>

                    <p className="mb-0">Total Profit & Loss</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between">
              <button
                    className="btn btn-info  custom-btn-action1 custom-target-icon"
                    data-pr-tooltip="View Record"
                    data-pr-position="top"
                    onClick={() => handleViewClick(userId)}
                    style={{
                      fontSize: '12px',         // Smaller font size
                      padding: '5px 10px',      // Adjust padding to make it smaller
                      borderRadius: '50%',      // Make the button circular
                      width: '25px',            // Set a fixed width
                      height: '25px',           // Set a fixed height
                      display: 'flex',          // Center the icon inside the button
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                
                  >
                    <i className="ri-list-radio" style={{ fontSize: '13px' }}></i>
                  </button>
                <h5 className="text-center flex-grow-1 m-0">Open Position</h5>
                <div>
                  {loadingRefresh ? (
                    <div className="d-flex align-items-center">
                      <div
                        className="spinner-border spinner-border-sm custom-spinner me-3"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-3">
                      <Tooltip target=".custom-target-icon" />
                      <i
                        className="custom-target-icon ri ri-refresh-line ri-lg me-3 p-text-secondary"
                        role="button"
                        data-pr-tooltip="Reload"
                        onClick={() => {
                          handleRefresh();
                          handleRefreshes();
                      }}
                        data-pr-position="top"
                      ></i>
                    </div>
                  )}
                </div>
              </div>
              <div className="table-responsive text-start p-3">
                <div className="d-flex justify-content-end mb-3">
                  <div
                    className=" me-3"
                    role="button"
                    onClick={handleToggleSelectAll}
                  >
                    {allSelected ? "Unselect All" : "Select All"}
                  </div>
                </div>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Instrument</th>
                      <th>Product Type</th>
                      <th>Option Type</th>
                      <th>Order Type</th>
                      <th>Exchange</th>
                      <th>Lots</th>
                      <th>LTP</th>
                      <th>Avg. Price</th>
                      <th>Profit & Loss</th>
                      {showActionColumn && <th>Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {positionData.openPositions.map((item, index) => (
                      <tr key={index}>
                        <td
                          style={{ wordWrap: "break-word", maxWidth: "150px" }}
                        >
                          <span className="instrument_symbol">
                            {item.tradingsymbol}
                          </span>{" "}
                          <span className="d-none instrument_token">
                            {item.symboltoken}
                          </span>
                        </td>
                        <td
                          style={{ wordWrap: "break-word", maxWidth: "110px" }}
                        >
                          <span className="instrument_producttype">
                            {item.producttype}
                          </span>
                        </td>
                        <td>{item.optiontype}</td>
                        <td className={item.color_ordertype}>
                          {item.ordertype}
                        </td>
                        <td>
                          <span className="instrument_exchange">
                            {item.exchange}
                          </span>
                        </td>
                        <td className={item.color_pnl}>
                          {item.lotquantity} Lots <br></br>
                          <span className="text-body-tertiary lot_size">
                            (1 Lot = {item.lotsize})
                          </span>
                        </td>
                        <td>
                          <span className={item.color_diff_percent}>
                            {item.ltp} ₹
                          </span>{" "}
                          <span className="text-body-tertiary">
                            ({item.dailyDiffPercent}%)
                          </span>
                        </td>
                        <td>{item.avg_price} ₹</td>
                        <td className={item.color_pnl}>{item.pnl} ₹</td>
                        {showActionColumn && (
                          <td>
                            <div className="d-flex align-items-center">
                              <input
                                type="checkbox"
                                checked={isChecked(
                                  item.tradingsymbol,
                                  item.symboltoken,
                                  parseInt(item.netqty / item.lotsize)
                                )}
                                onChange={() =>
                                  handleCheckboxChange(
                                    item.tradingsymbol,
                                    item.symboltoken,
                                    parseInt(item.netqty / item.lotsize)
                                  )
                                }
                                id={`${item.tradingsymbol}-checkbox`}
                              />
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    {showActionColumn && (
                      <tr>
                        <td colSpan="10">
                          <div className="d-flex justify-content-end">
                            <button
                              type="button"
                              className="btn btn-danger btn-sm rounded"
                              onClick={exitPosition}
                              disabled={selectedCount == 0 || loadingexit}
                            >
                              {loadingexit ? (
                                <>
                                  <div
                                    className="spinner-border me-3"
                                    role="status"
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                  Executing {selectedCount} Selected
                                </>
                              ) : (
                                <>
                                  <i className="ri-send-plane-fill ri-lg me-3"></i>
                                  Exit {selectedCount} Selected
                                </>
                              )}
                            </button>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tfoot>
                </table>
              </div>
            </div>
            {positionData.closedPositions &&
              positionData.closedPositions.length > 0 && (
                <div className="card mt-3">
                  <div className="card-header d-flex align-items-center justify-content-between">
                    <h5 className="text-center flex-grow-1 m-0">
                      Closed Position
                    </h5>

                    {loadingRefresh ? (
                      <div className="d-flex align-items-center">
                        <div
                          className="spinner-border spinner-border-sm custom-spinner me-3 mt-3"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <div className="mt-3">
                        <Tooltip target=".custom-target-icon" />
                        <i
                          className="custom-target-icon ri ri-refresh-line ri-lg me-3 p-text-secondary"
                          role="button"
                          data-pr-tooltip="Reload"
                          onClick={handleRefresh}
                          data-pr-position="top"
                        ></i>
                      </div>
                    )}
                  </div>

                  <div className="table-responsive text-start p-3">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Instrument</th>
                          <th>Product Type</th>
                          <th>Option Type</th>
                          <th>Exchange</th>
                          <th>Lots</th>
                          <th>LTP</th>
                          <th>Buy Price</th>
                          <th>Sell Price</th>
                          <th>Profit & Loss</th>
                        </tr>
                      </thead>
                      <tbody>{positionData.closedPositions}</tbody>
                    </table>
                  </div>
                </div>
              )}
          </div>
          {filteredData.length > 0 ? (
            <div className="col-xl-3 col-lg-3 col-md-5 col-12">
              {/* Right side content */}
              <div className="right-side-content">
                <div className="card">
                  <div className="d-flex justify-content-around mt-5">
                    <Tooltip target=".custom-target-btn" />

                    <button
                      type="button"
                      className="btn btn-danger rounded btn-md w-100 ms-5 me-5 custom-target-btn"
                      onClick={handleExitAllPending}
                      disabled={filteredeData.length === 0 || loadingpending}
                      data-pr-tooltip="Exit all instruments in teacher & all students at a time"
                      data-pr-position="top"
                      style={{ cursor: "pointer" }}
                    >
                      {loadingpending ? (
                        <>
                          <div className="spinner-border me-3" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                          Executing all Pendings
                        </>
                      ) : (
                        <>
                          <i className="ri-send-plane-fill me-3 "></i> Exit All
                          Pendings
                        </>
                      )}
                    </button>
                  </div>
                  {/* <div className="card-body">
                    <div className="d-flex justify-content-between mb-5 ">
                      <IconField iconPosition="left">
                        <InputIcon className="ri ri-search-line"></InputIcon>
                        <InputText
                          type="search"
                          placeholder="Search "
                          value={globalFilter}
                          onChange={(e) => setGlobalFilter(e.target.value)}
                          className="rounded-pill custom-search-box"
                          // onClick={clearSearch}
                        />
                      </IconField> */}
                      <div className="card-body">
          <div className="d-flex justify-content-start  mb-3 ">
            <div className="flex-grow-1 input-group input-group-sm input-group-merge rounded-pill">
              <span className="input-group-text" id="basic-addon-search31">
                <i className="ri-search-line lh-1 ri-20px"></i>
              </span>
              <input
                type="text"
                className="form-control chat-search-input ps-8"
                placeholder="Search"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
                aria-label="Search..."
                aria-describedby="basic-addon-search31"
              />
            </div>

                      {loadingRefreshes ? (
                        <div className="d-flex align-items-center">
                          <div
                            className="spinner-border spinner-border-sm ms-3 custom-spinner"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div className="mt-3">
                          <Tooltip target=".custom-target-icon" />
                          <i
                            className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
                            role="button"
                            data-pr-tooltip="Reload"
                            onClick={() => {
                              handleRefresh();
                              handleRefreshes();
                          }}
                            data-pr-position="top"
                          ></i>
                        </div>
                      )}
                    </div>

                    <hr />

                    <div
                      className="virtual-scroller-container"
                      style={{
                        height: "500px",
                        overflowY: "auto",
                        overflowX: "hidden",
                      }}
                    >
                      <VirtualScroller
                        items={filteredData}
                        itemSize={70}
                        itemTemplate={(item, index) => {
                          if (!item || !item.positions) return null; // Ensure item and positions exist

                          return (
                            <div
                              key={index}
                              className="border-bottom mb-3 pb-3"
                            >
                              <div className="d-flex align-items-start mb-3">
                              <button
                    className="btn btn-info me-1  btn-xs  custom-target-icon"
                    data-pr-tooltip="View Record"
                    data-pr-position="top"
                    onClick={() => {
                      setCurrentStudentId(item.student_id);
                      fetchDataStudentOrderBook(item.student_id);
                      setSelectedStudentName(item.name);
                    }}
                    style={{
                      fontSize: '12px',         // Smaller font size
                      padding: '5px 10px',      // Adjust padding to make it smaller
                      borderRadius: '50%',      // Make the button circular
                      width: '25px',            // Set a fixed width
                      height: '25px',           // Set a fixed height
                      display: 'flex',          // Center the icon inside the button
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                
                  >
                    <i className="ri-list-radio" style={{ fontSize: '14px' }}></i>
                  </button>
                                <p className="text-start mb-0 fs-6 text-black flex-grow-1">
                                  {item.name
                                    ? capitalizeFirstLetter(item.name)
                                    : "Unknown"}
                                </p>
                                <button
                                  type="button"
                                  className="btn btn-danger btn-xs btn-fill-danger waves-effect"
                                  onClick={() =>
                                    handleExitAllInstruments(item.student_id)
                                  }
                                  disabled={loadingMaps[item.student_id]}
                                >
                                  {loadingMaps[item.user_id] ? (
                                    <div
                                      className="spinner-border me-2"
                                      role="status"
                                    >
                                      <span className="visually-hidden">
                                        Loading...
                                      </span>
                                    </div>
                                  ) : (
                                    <i className="ri-send-plane-fill me-2"></i>
                                  )}{" "}
                                  {loadingMaps[item.user_id] ? "Exit" : "Exit"}
                                </button>
                                {/* Handle Exit logic here */}
                              </div>

                              {item.positions.map((lot, lotIndex) => (
                                <div key={lotIndex} className="mb-3">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <di className="fs-6 text-black text-start">
                                      {lot.tradingsymbol}
                                    </di>
                                    <di className="text-end text-muted">
                                      <strong className="text-muted ms-3">
                                        LTP:
                                      </strong>{" "}
                                      {lot.ltp} ₹
                                    </di>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div className="text-start text-primary1">
                                      <strong className="text-muted">
                                        {lot.transactiontype}:
                                      </strong>{" "}
                                      {lot.transactiontype === "BUY" ? (
                                        <span className="text-success">
                                          {lot.avgprice} ₹
                                        </span>
                                      ) : (
                                        <span className="text-danger">
                                          {lot.avgprice} ₹
                                        </span>
                                      )}
                                    </div>
                                    <div className="text-end text-muted">
  <strong className="text-muted">P&L:</strong>{" "}
  <span
    className={
      lot.pnl < 0
        ? "text-danger"
        : lot.pnl > 0
        ? "text-success"
        : "text-muted"
    }
  >
    {lot.pnl} ₹
  </span>
</div>


                                  </div>
                                </div>
                              ))}
                            </div>
                          );
                        }}
                        delay={250}
                        className="border-1 surface-border border-round"
                        style={{ height: "500px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-xl-3 col-lg-3 col-md-5 col-12 ">
             <div className="right-side-content">
  <div className="card h-100">
    <div className="card-body d-flex flex-column justify-content-center align-items-center">
      <div className="spinner-border text-secondary mb-3" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      <h5 className="text-center">No more students available for order processing.</h5>
    </div>
  </div>
</div>

            </div>
          )}


<Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} size="xl" centered>
  <Modal.Header closeButton>
  <Modal.Title className="text-center w-100 fs-5">
   Order Book
</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {loadingOrder ? (
      <div className="text-center">
        <i className="ri-loader-4-line ri-spin ri-lg"></i> Loading...
      </div>
    ) : error ? (
      <div className="text-danger text-center">{error.message}</div>
    ) : (
      <div>
    
       
        <DataTable
          className="custom-column-border text-center"
          value={dataOrder || []}
          paginator
          rows={20}
          showGridlines
          globalFilter={globalFilter}
          emptyMessage="No records found"
        >
          <DataTable.Column field="tradingsymbol"  align="center"
      className="custom-column-border" header="Symbols" />
          <DataTable.Column
            field="transactiontype"
             align="center"
      className="custom-column-border"
            header="Transaction Type"
            body={(rowData) => (
              <span style={{ color: rowData.transactiontype === "BUY" ? "green" : "orange" }}>
                {rowData.transactiontype}
              </span>
            )}
          />
          <DataTable.Column field="exchange" header="Exchange"  align="center"
      className="custom-column-border"/>
          <DataTable.Column field="strikeprice" header="Strike Price"  align="center"
      className="custom-column-border" />
          <DataTable.Column field="optiontype" header="Option Type"   align="center"
      className="custom-column-border text-capitalize"/>
          <DataTable.Column
            field="orderstatus"
            header="Order Status"
             align="center"
      className="custom-column-border text-capitalize"
            body={(rowData) => (
              <span style={{ color: rowData.orderstatus === "complete" ? "green" : "orange" }}>
                {rowData.orderstatus}
              </span>
            )}
          />
        </DataTable>
      </div>
    )}
  </Modal.Body>
</Modal>

<Modal
        show={isModalOpenStudent}
        onHide={() => setIsModalOpenStudent(false)}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
        <Modal.Title className="text-center text-capitalize w-100 fs-5">
  {selectedStudentName ? `${selectedStudentName}'s Order Book` : 'Student Order Book'}
</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingStudentOrder ? (
            <div className="text-center">Loading...</div>
          ) : dataStudentOrder.length > 0 ? (
            <div>
             
              <DataTable
                className="custom-column-border text-center"
                value={dataStudentOrder}
                paginator
                rows={20}
                showGridlines
                globalFilter={globalFilter}
                emptyMessage="No records found"
              >
                <DataTable.Column field="tradingsymbol" header="Symbols"   align="center"
      className="custom-column-border text-capitalize"/>
                <DataTable.Column
                  field="transactiontype"
                  header="Transaction Type"
                    align="center"
      className="custom-column-border "
                  body={(rowData) => (
                    <span
                      style={{
                        color: rowData.transactiontype === "BUY" ? "green" : "orange",
                      }}
                    >
                      {rowData.transactiontype}
                    </span>
                  )}
                />
                <DataTable.Column field="exchange" header="Exchange"   align="center"
      className="custom-column-border text-capitalize"/>
                <DataTable.Column field="strikeprice" header="Strike Price"   align="center"
      className="custom-column-border text-capitalize"/>
                <DataTable.Column field="optiontype" header="Option Type"   align="center"
      className="custom-column-border text-capitalize"/>
                <DataTable.Column
                  field="orderstatus"
                    align="center"
      className="custom-column-border text-capitalize"
                  header="Order Status"
                  body={(rowData) => (
                    <span
                      style={{
                        color: rowData.orderstatus === "complete" ? "green" : "orange",
                      }}
                    >
                      {rowData.orderstatus}
                    </span>
                  )}
                />
              </DataTable>
            </div>
          ) : (
            <div>No data found</div>
          )}
        </Modal.Body>
      </Modal>
        </div>
      </div>
      {/* </div> */}
      <Footer />
    </>
  );
};

export default Position;

const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red"; // Apply custom modal background colors
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};
